import Vue from "vue";
import Column from "../components/grid/column.vue";
import Matrix from "../components/grid/matrix.vue";
import Row from "../components/grid/row.vue";
import RowGroup from "../components/grid/rowGroup.vue";
import CustomIcon from "../components/icon.vue";
import Pen from "../components/pen.vue";
import Pill from "../components/pill.vue";

//------------------------ Components
Vue.component("row", Row);
Vue.component("column", Column);
Vue.component("row-group", RowGroup);
Vue.component("matrix", Matrix);
Vue.component("pen", Pen);
Vue.component("pill", Pill);

//------------------------ Alias
Vue.component("pencil", Vue.extend({
  props: {
    mode: {
      default: 'pencil'
    }
  },
  extends: Pen
}));

Vue.component("crayon", Vue.extend({
  props: {
    mode: {
      default: 'crayon'
    }
  },
  extends: Pen
}));

Vue.component("typewriter", Vue.extend({
  props: {
    mode: {
      default: 'typewriter'
    }
  },
  extends: Pen
}));

Vue.component("t-row", Vue.extend({
  props: {
    tableElement : {
      default: true
    }
  },
  extends: Row
}));

Vue.component("t-column", Vue.extend({
  props: {
    tableElement : {
      default: true
    }
  },
  extends: Column
}));

Vue.component("t-prefix", Vue.extend({
  props: {
    type: {
      default: 'prefix'
    },
    tableElement : {
      default: true
    }
  },
  extends: Column
}));

Vue.component("t-suffix", Vue.extend({
  props: {
    type: {
      default: 'suffix'
    },
    tableElement : {
      default: true
    }
  },
  extends: Column
}));

Vue.component("row-layout", Vue.extend({props: {additionalClass: {default: 'layout'}}, extends: Row}));
Vue.component("b-row", Vue.extend({props: {type: {default: 'bootstrap'}}, extends: Row}));
Vue.component("b-column", Vue.extend({props: {type: {default: 'bootstrap'}}, extends: Column}));
Vue.component("prefix", Vue.extend({props: {type: {default: 'prefix'}}, extends: Column}));
Vue.component("suffix", Vue.extend({props: {type: {default: 'suffix'}}, extends: Column}));

Vue.component("icon", CustomIcon);