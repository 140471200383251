<template>
  <component :is="tag" :class="classes" :role="role">
    <template v-if="isHeading">
      <h6><slot></slot></h6>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </component>
</template>

<script lang="ts">
  import Vue from "vue";

  export default Vue.extend({
    /*
      tag: ['div']
      type: ['Bootstrap', 'Custom']
      size: ['lg-12' ,'md50', ['xs-12', 'md-12', 'lg-12'], ...]
    */
    props: {
      tag: {
        type: String,
        default: 'div'
      },
      type: {
        type: String,
        default: 'column'
      },
      size: {
        default: ''
      },
      isHeading: {
        type: Boolean,
        default: false
      },
      allowGrowing: {
        type: Boolean,
        default: false
      },
      allowShrink: {
        type: Boolean,
        default: false
      },
    },
    data: function(){
      return {
        grid: {
          'bootstrap': 'col',
          'prefix': 'prefix',
          'column': 'column',
          'suffix': 'suffix',
        },
        minSize: 25,
        maxSize: 800,
        increment: 5,
        role: this.isHeading ? 'columnheader' : 'cell'
      }
    },
    computed: {
      classes(): object {
        return [
          this.grid[this.type],
          this.finalSize,
          {
            'allow-growing': this.allowGrowing,
            'allow-shrink': this.allowShrink
          }
        ]
      },
      finalSize() {
        let result = this.size;
        if(/^\d+$/.test(this.size)){
          result = 'fix-' + this.processNumber(this.size);
        }
        return result;
      }
    },
    methods: {
      processNumber(size) {
        let newSize = parseInt(size, 10);
        newSize = (newSize < this.minSize) ? this.minSize : newSize;
        newSize = (newSize > this.maxSize) ? this.maxSize : newSize;

        let rest = newSize % this.increment;
        let hasNoClassEquivalent = rest > 0;
        if(hasNoClassEquivalent){
          newSize = newSize - rest + this.increment;
        }

        return newSize.toString();
      }
    }
  });
</script>
