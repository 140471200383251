<template>
  <component :is="tag" class="pill" :class="[color, {'full-sz': full}]" >
    <row class="component-row" :data-c-tooltip="tooltipText">

      <column ref="pillTitleDisplay">
        <icon v-if="hasIcon" :class="iconClass"></icon> {{valueOrDefault}}
      </column>

      <suffix v-if="hasEdit">
        <button @click="editPill" type="button" class="btn-icon clear edit">
          <icon class="pencil"></icon>
          <span class="sr-only">Edit Pill</span>
        </button>
      </suffix>

      <suffix v-if="hasRemove">
        <button @click="removePill" type="button" class="btn-icon clear close">
          <icon class="times"></icon>
          <span class="sr-only">Remove Pill</span>
        </button>
      </suffix>
    </row>
  </component>
</template>

<script lang="ts">
  import Vue from "vue";
  export default Vue.extend({
    props: {
      color: {
        type: String,
        default: 'blue',
      },
      full: {
        type: Boolean,
        default: false,
      },
      value: {
        type: String,
        default: ''
      },
      hasTooltip: {
        type: Boolean,
        default: false
      },
      tooltipValue: {
        type: String
      },
      iconClass:{
        type: String,
        default: ""
      },
      hasIcon:{
        type: Boolean,
        default: false
      },
      tag: {
        type: String,
        default: 'div'
      },
      placeholder: {
        type: String,
        default: ''
      },
    },
    data: function(){
      return {
        hasRemove: this.$listeners.onRemove,
        hasEdit: this.$listeners.onEdit,
        tooltipText: null,
      }
    },
    mounted() {
      let pillTitleDisplay = this.$refs?.pillTitleDisplay
      if (pillTitleDisplay && pillTitleDisplay.$el.offsetWidth < pillTitleDisplay.$el.scrollWidth) {
        this.setTooltipText();
      }
    },
    methods: {
      removePill($event){
        this.$emit('onRemove', $event);
      },
      editPill(){
        this.$emit('onEdit');
      },
      setTooltipText() {
        // TODO init tooltip when new pill is added (works only after page refresh)
        this.tooltipText = this.tooltipValue || this.valueOrDefault;
      }
    },
    computed: {
      valueOrDefault(): string {
        return this.value || this.placeholder
      }
    }
  });
</script>
