<template>
  <component :is="tag" class="icon" :class="[color, {'colorized': color !== null}]"></component>
</template>

<script lang="ts">
  import Vue from "vue";
  export default Vue.extend({
    props: {
      color: {
        type: String,
        default: null
      },
      tag: {
        type: String,
        default: 'span'
      }
    }
  });
</script>