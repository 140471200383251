<template>
  <component :is="newTag" :class="classes">
    <slot></slot>
  </component>
</template>

<script lang="ts">
  import Vue from "vue";

  export default Vue.extend({
    props: {
      tag: {
        type: String,
        default: ''
      },
      mode: {
        type: String,
        default: 'pencil'
      },
      color: {
        type: String,
        default: 'content'
      },
    },
    data: function() {
      return {
        modality: {
          '': 'em',
          'pen': 'em',
          'pencil': 'em',
          'crayon': 'strong',
          'typewriter': 'strong',
        }
      }
    },
    computed: {
      newTag() {
        return this.tag === '' ? this.modality[this.mode] : this.tag;
      },
      classes(): object {
        return [
          this.mode,
          this.color,
          ];
      },
    },
  });
</script>
