<template>
  <component :is="tag" role="rowgroup">
    <slot></slot>
  </component>
</template>

<script lang="ts">
  import Vue from "vue";

  export default Vue.extend({
    props: {
      tag: {
        type: String,
        default: 'div'
      },
    },
    data: function(){
      return {}
    }
  });
</script>