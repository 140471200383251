
<div :class="classes"
  class="word-wrap"
  :style="{'width': tableBodyWidth}"
  role="table"
  aria-describedby="table"
>
  <p class="sr-only">A table that shows the data as a list or a grid</p>

  <template v-if="hasHeader">
    <row-group class="table-header">
      <row is-heading>
        <slot name="table-header"></slot>
      </row>
    </row-group>
  </template>

  <row-group class="table-body">
    <template v-if="hasData">
      <slot name="table-body"></slot>
    </template>

    <template v-else>
      <row class="prompt">
        <column>
          <strong>{{fetchingMessage}}</strong>
        </column>
      </row>
    </template>
  </row-group>

  <template v-if="hasFooter">
    <row-group class="table-footer">
      <slot name="table-footer"></slot>
    </row-group>
  </template>

</div>
