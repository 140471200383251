
<component :is="tag" class="pill" :class="[color, {'full-sz': full}]" >
  <row class="component-row" :data-c-tooltip="tooltipText">

    <column ref="pillTitleDisplay">
      <icon v-if="hasIcon" :class="iconClass"></icon> {{valueOrDefault}}
    </column>

    <suffix v-if="hasEdit">
      <button @click="editPill" type="button" class="btn-icon clear edit">
        <icon class="pencil"></icon>
        <span class="sr-only">Edit Pill</span>
      </button>
    </suffix>

    <suffix v-if="hasRemove">
      <button @click="removePill" type="button" class="btn-icon clear close">
        <icon class="times"></icon>
        <span class="sr-only">Remove Pill</span>
      </button>
    </suffix>
  </row>
</component>
